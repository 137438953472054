import React, { Component } from 'react';
import '../../Modal.css';
import { systemAuth } from '../../../../apis/backend';
import { connect } from 'react-redux';
import { updateSelectedSystemUser } from '../../../../actions/adminActions/systemUserActions';

class EditModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: '',
      location: ''
    };
  }

  async systemUserUpdateRequest() {
    await systemAuth
      .patch(
        `/${this.props.selectedSystemUser}`,
        {
          username: this.state.username,
          location: this.state.location
        },
        { headers: { authorization: this.props.employeeToken } }
      )
      .catch((response) => {
        this.setState({
          username: '',
          location: ''
        });
        console.log(response);
        alert('Sorry, an error occurred. Please check your entries.');
      });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      prevProps.selectedSystemUser !== this.props.selectedSystemUser &&
      this.props.selectedSystemUser !== 0
    ) {
      const systemUser = this.props.systemUsers.find(
        (obj) => obj.id === this.props.selectedSystemUser
      );
      if (systemUser) {
        this.setState({
          username: systemUser.username,
          password: systemUser.password,
          location: systemUser.location
        });
      }
    }
  }

  render() {
    return (
      <div id="exampleModal" className="modal fade" style={{ overflow: 'scroll' }}>
        <div className="modal-dialog modal-dialog-centered lg">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Edit System Login</h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => this.props.updateSelectedSystemUser(0)}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="input-group input-group-sm mb-3">
                <div className="input-group-prepend">
                  <span className="input-group-text" id="inputGroup-sizing-sm">
                    Username
                  </span>
                </div>
                <input
                  type="text"
                  className="form-control"
                  aria-label="Sizing example input"
                  aria-describedby="inputGroup-sizing-sm"
                  value={this.state.username}
                  placeholder="Username"
                  onChange={(e) => this.setState({ username: e.target.value })}
                />
              </div>
              <div className="input-group input-group-sm mb-3">
                <div className="input-group-prepend">
                  <span className="input-group-text" id="inputGroup-sizing-sm">
                    Location
                  </span>
                </div>
                <select
                  className="form-control"
                  aria-label="Sizing example input"
                  aria-describedby="inputGroup-sizing-sm"
                  value={this.state.location}
                  onChange={(e) => this.setState({ location: e.target.value })}
                >
                  {this.props.systemLocations.map((location) => (
                    <option key={location.id} value={location.id}>
                      {location.location}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
                onClick={() => this.props.updateSelectedSystemUser(0)}
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-dark"
                data-dismiss="modal"
                onClick={() => {
                  this.systemUserUpdateRequest();
                  this.props.updateSelectedSystemUser(0);
                }}
              >
                Save changes
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  systemUsers: state.admin.admin.systemUsers,
  selectedSystemUser: state.admin.systemUsers.selectedSystemUser,
  systemLocations: state.admin.admin.systemLocations,
  employeeToken: state.auth.employeeToken
});

export default connect(mapStateToProps, { updateSelectedSystemUser })(EditModal);
