import React, { Component } from 'react';
import './../../Modal.css';
import { inventory } from '../../../../apis/backend';
import { connect } from 'react-redux';
import {
  updateInventoryFilter,
  updateSelectedInventory
} from '../../../../actions/adminActions/adminInventoryActions';

class EditModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      itemNumber: '',
      type: '',
      serialNumber: '',
      estValue: 0,
      make: '',
      itemModel: ''
    };
  }

  async inventoryUpdateRequest() {
    try {
      await inventory
        .patch(
          `/${this.props.selectedInventory}`,
          {
            itemNumber: this.state.itemNumber,
            inventoryTypeId: this.state.type,
            serialNumber: this.state.serialNumber,
            estValue: this.state.estValue,
            make: this.state.make,
            itemModel: this.state.itemModel
          },
          { headers: { authorization: this.props.employeeToken } }
        )
        .then(() => {
          // this.setState({ itemNumber: '', type: '' });
        })
        .catch((response) => {
          console.log(response);
          alert('Sorry, an error occurred. Please check your entries.');
        });
    } catch (err) {
      console.log('Request Failed.');
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      prevProps.selectedInventory !== this.props.selectedInventory &&
      this.props.selectedInventory !== 0
    ) {
      const inv = this.props.inventory.find((obj) => obj.id === this.props.selectedInventory);
      const type = this.props.inventoryTypes.find((obj) => obj.name === inv.type);
      if (inv) {
        this.setState({
          itemNumber: inv.itemNumber,
          type: type.id,
          serialNumber: inv.serialNumber,
          estValue: inv.estValue,
          make: inv.make,
          itemModel: inv.itemModel
        });
      }
    }
  }

  render() {
    return (
      <div id="exampleModal" className="modal fade" style={{ overflow: 'scroll' }}>
        <div className="modal-dialog modal-dialog-centered lg">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Edit Inventory</h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => this.props.updateSelectedInventory(0)}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="input-group input-group-sm mb-3">
                <div className="input-group-prepend">
                  <span className="input-group-text" id="inputGroup-sizing-sm">
                    Item Number
                  </span>
                </div>
                <input
                  type="number"
                  className="form-control"
                  aria-label="Sizing example input"
                  aria-describedby="inputGroup-sizing-sm"
                  min="1"
                  value={this.state.itemNumber}
                  placeholder="Item Number"
                  onChange={(e) => this.setState({ itemNumber: e.target.value })}
                />
              </div>
              <div className="input-group input-group-sm mb-3">
                <div className="input-group-prepend">
                  <span className="input-group-text" id="inputGroup-sizing-sm">
                    Type
                  </span>
                </div>
                <select
                  className="form-control"
                  value={this.state.type}
                  onChange={(e) => {
                    console.log(e.target.value);
                    this.setState({ type: e.target.value });
                  }}
                >
                  <option value="" disabled>
                    Select Inventory Type
                  </option>
                  {this.props.inventoryTypes.map((type) => (
                    <option value={type.id} key={type.id}>
                      {type.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="input-group input-group-sm mb-3">
                <div className="input-group-prepend">
                  <span className="input-group-text" id="inputGroup-sizing-sm">
                    Serial Number
                  </span>
                </div>
                <input
                  type="text"
                  className="form-control"
                  aria-label="Sizing example input"
                  aria-describedby="inputGroup-sizing-sm"
                  min="1"
                  value={this.state.serialNumber}
                  placeholder="Serial Number"
                  onChange={(e) => this.setState({ serialNumber: e.target.value })}
                />
              </div>
              <div className="input-group input-group-sm mb-3">
                <div className="input-group-prepend">
                  <span className="input-group-text" id="inputGroup-sizing-sm">
                    Estimated Value
                  </span>
                </div>
                <input
                  type="number"
                  className="form-control"
                  aria-label="Sizing example input"
                  aria-describedby="inputGroup-sizing-sm"
                  min="1"
                  value={this.state.estValue}
                  placeholder="Estimate"
                  onChange={(e) => this.setState({ estValue: e.target.value })}
                />
              </div>
              <div className="input-group input-group-sm mb-3">
                <div className="input-group-prepend">
                  <span className="input-group-text" id="inputGroup-sizing-sm">
                    Make
                  </span>
                </div>
                <input
                  type="text"
                  className="form-control"
                  aria-label="Sizing example input"
                  aria-describedby="inputGroup-sizing-sm"
                  min="1"
                  value={this.state.make}
                  placeholder="Make"
                  onChange={(e) => this.setState({ make: e.target.value })}
                />
              </div>
              <div className="input-group input-group-sm mb-3">
                <div className="input-group-prepend">
                  <span className="input-group-text" id="inputGroup-sizing-sm">
                    Model
                  </span>
                </div>
                <input
                  type="text"
                  className="form-control"
                  aria-label="Sizing example input"
                  aria-describedby="inputGroup-sizing-sm"
                  min="1"
                  value={this.state.itemModel}
                  placeholder="Model"
                  onChange={(e) => this.setState({ itemModel: e.target.value })}
                />
              </div>
            </div>

            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
                onClick={() => this.props.updateSelectedInventory(0)}
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-dark"
                data-dismiss="modal"
                onClick={() => {
                  this.inventoryUpdateRequest();
                }}
                disabled={
                  !(
                    this.state.itemNumber !== '' &&
                    this.state.type !== '' &&
                    this.state.itemNumber >= 1
                  )
                }
              >
                Save changes
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  inventory: state.admin.admin.inventory,
  selectedInventory: state.admin.inventory.selectedInventory,
  inventoryTypes: state.admin.admin.inventoryTypes,
  employeeToken: state.auth.employeeToken
});

export default connect(mapStateToProps, {
  updateInventoryFilter,
  updateSelectedInventory
})(EditModal);
