import React, { Component } from 'react';
import './../../Modal.css';
import { updateSelectedFlag } from '../../../../actions/adminActions/usersActions';
import { noteflags } from '../../../../apis/backend';
import { connect } from 'react-redux';

class CreateNoteFlagsModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      color: '#000000',
      description: '',
      disableCheckin: false,
      clearByAdmin: false,
      setAutoClear: false,
      daysTillCleared: 0
    };
  }

  async createNoteFlag() {
    await noteflags
      .post(
        '/',
        {
          name: this.state.name,
          color: this.state.color,
          description: this.state.description,
          daysTillCleared: this.state.daysTillCleared,
          disableCheckin: this.state.disableCheckin,
          clearByAdmin: this.state.clearByAdmin
        },
        { headers: { authorization: this.props.employeeToken } }
      )
      .catch((response) => console.log(response));
    this.setState({
      name: '',
      color: '#000000',
      description: '',
      disableCheckin: false,
      clearByAdmin: false,
      setAutoClear: false,
      daysTillCleared: 0
    });
  }

  renderDaysTillCleared() {
    if (this.state.setAutoClear) {
      return (
        <>
          <div className="input-group-prepend" style={{ paddingLeft: '10px' }}>
            <span className="input-group-text" id="inputGroup-sizing-sm">
              Days Till Cleared
            </span>
          </div>
          <input
            type="number"
            className="form-control"
            aria-label="Sizing example input"
            aria-describedby="inputGroup-sizing-sm"
            value={this.state.daysTillCleared}
            onChange={(e) => this.setState({ daysTillCleared: parseInt(e.target.value) })}
          />
        </>
      );
    }
  }

  render() {
    return (
      <div id="createNoteFlag" className="modal fade">
        <div className="modal-dialog modal-dialog-centered lg" style={{ maxWidth: '700px' }}>
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Flags</h5>
              <button
                type="button"
                data-target="#noteFlagsModal"
                className="close"
                data-toggle="modal"
                data-dismiss="modal"
                onClick={() => {
                  this.props.updateSelectedFlag(0);
                }}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>

            <div className="modal-body" style={{ padding: '0' }}>
              <div className="input-group input-group-sm mb-3 mt-3" style={{ padding: '0 10px' }}>
                <div className="input-group-prepend">
                  <span className="input-group-text" id="inputGroup-sizing-sm">
                    Flag Name
                  </span>
                </div>
                <input
                  type="text"
                  className="form-control"
                  aria-label="Sizing example input"
                  aria-describedby="inputGroup-sizing-sm"
                  value={this.state.name}
                  onChange={(e) => this.setState({ name: e.target.value })}
                />
              </div>

              <div
                className="input-group input-group-sm mb-3"
                style={{ padding: '0 10px', maxWidth: '35%' }}
              >
                <div className="input-group-prepend">
                  <span className="input-group-text" id="inputGroup-sizing-sm">
                    Flag Color
                  </span>
                </div>
                <input
                  type="color"
                  className="form-control"
                  aria-label="Sizing example input"
                  aria-describedby="inputGroup-sizing-sm"
                  value={this.state.color}
                  onChange={(e) => this.setState({ color: e.target.value })}
                />
              </div>

              <div className="input-group input-group-sm mb-3" style={{ padding: '0 10px' }}>
                <div className="input-group-prepend">
                  <span className="input-group-text" id="inputGroup-sizing-sm">
                    Description
                  </span>
                </div>
                <textarea
                  type="text"
                  className="form-control"
                  aria-label="Sizing example input"
                  aria-describedby="inputGroup-sizing-sm"
                  value={this.state.description}
                  placeholder="Flag description..."
                  onChange={(e) => this.setState({ description: e.target.value })}
                />
              </div>

              <div className="input-group input-group-sm mb-3" style={{ padding: '0 10px' }}>
                <div className="input-group-prepend">
                  <span className="input-group-text" id="inputGroup-sizing-sm">
                    Disable Checkin
                  </span>
                </div>
                <select
                  className="form-control"
                  value={this.state.disableCheckin}
                  onChange={(e) => this.setState({ disableCheckin: e.target.value === 'true' })}
                >
                  <option value={false}>No</option>
                  <option value={true}>Yes</option>
                </select>
                <div className="input-group-prepend" style={{ paddingLeft: '10px' }}>
                  <span className="input-group-text" id="inputGroup-sizing-sm">
                    Clear By Admin
                  </span>
                </div>
                <select
                  className="form-control"
                  value={this.state.clearByAdmin}
                  onChange={(e) => {
                    this.setState({
                      clearByAdmin: e.target.value === 'true',
                      daysTillCleared: e.target.value === 'true' ? 0 : this.state.daysTillCleared
                    });
                  }}
                >
                  <option value={false}>No</option>
                  <option value={true}>Yes</option>
                </select>
              </div>

              {this.state.clearByAdmin ? (
                <></>
              ) : (
                <div className="input-group input-group-sm mb-3" style={{ padding: '0 10px' }}>
                  <div className="input-group-prepend">
                    <span className="input-group-text" id="inputGroup-sizing-sm">
                      Auto-clear Flag
                    </span>
                  </div>
                  <select
                    className="form-control"
                    value={this.state.setAutoClear}
                    onChange={(e) => {
                      this.setState({
                        setAutoClear: e.target.value === 'true',
                        daysTillCleared: e.target.value === 'true' ? 0 : this.state.daysTillCleared
                      });
                    }}
                  >
                    <option value={false}>No</option>
                    <option value={true}>Yes</option>
                  </select>
                  {this.renderDaysTillCleared()}
                </div>
              )}
            </div>

            <div className="modal-footer">
              <button
                type="button"
                data-target="#noteFlagsModal"
                className="btn btn-secondary"
                data-toggle="modal"
                data-dismiss="modal"
                onClick={() => {
                  this.props.updateSelectedFlag(0);
                }}
              >
                Cancel
              </button>
              <button
                type="button"
                data-target="#noteFlagsModal"
                className="btn btn-dark"
                data-toggle="modal"
                data-dismiss="modal"
                disabled={
                  !(
                    this.state.color !== '' &&
                    this.state.color !== '#ffffff' &&
                    this.state.description !== ''
                  )
                }
                onClick={() => {
                  this.createNoteFlag();
                }}
              >
                Create New Flag
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  noteFlags: state.admin.admin.noteFlags,
  selectedFlag: state.admin.users.selectedFlag,
  employeeToken: state.auth.employeeToken
});

export default connect(mapStateToProps, { updateSelectedFlag })(CreateNoteFlagsModal);
