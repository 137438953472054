import React, { Component } from 'react';
import '../../Modal.css';
import { raspberrypi } from '../../../../apis/backend';
import { connect } from 'react-redux';

class AddModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mac: '',
      nickname: ''
    };
  }

  async scannerRequest() {
    try {
      await raspberrypi
        .post(
          '/',
          {
            mac: this.state.mac,
            nickname: this.state.nickname
          },
          { headers: { authorization: this.props.employeeToken } }
        )
        .then(() => {
          document.getElementById(this.state.mac).style.display = 'none';
          this.setState({
            mac: '',
            nickname: ''
          });
        })
        .catch((response) => {
          console.log(response);
          alert('Sorry, an error occurred. Please check your entries.');
        });
    } catch (err) {
      console.log('Request Failed.');
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.raspberryPiSelected !== this.props.raspberryPiSelected) {
      this.setState({
        mac: this.props.raspberryPiSelected
      });
    }
  }

  render() {
    return (
      <div id="addModal" className="modal fade" style={{ overflow: 'scroll' }}>
        <div className="modal-dialog modal-dialog-centered lg">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Add Scanner</h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="input-group input-group-sm mb-3">
                <div className="input-group-prepend">
                  <span className="input-group-text" id="inputGroup-sizing-sm">
                    MAC
                  </span>
                </div>
                <div className="input-group-prepend">
                  <span className="input-group-text" id="inputGroup-sizing-sm">
                    {this.state.mac}
                  </span>
                </div>
              </div>
              <div className="input-group input-group-sm mb-3">
                <div className="input-group-prepend">
                  <span className="input-group-text" id="inputGroup-sizing-sm">
                    Name
                  </span>
                </div>
                <input
                  type="text"
                  className="form-control"
                  aria-label="Sizing example input"
                  aria-describedby="inputGroup-sizing-sm"
                  value={this.state.nickname}
                  placeholder="Name"
                  onChange={(e) => this.setState({ nickname: e.target.value })}
                />
              </div>
            </div>

            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-dismiss="modal">
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-dark"
                data-dismiss="modal"
                onClick={() => {
                  this.scannerRequest();
                }}
                disabled={!(this.state.mac !== '' && this.state.nickname !== '')}
              >
                Create Scanner
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  scanners: state.admin.admin.raspberryPis,
  selectedScanner: state.admin.scanners.selectedScanner,
  raspberryPiSelected: state.admin.scanners.raspberryPiSelected,
  employeeToken: state.auth.employeeToken
});

export default connect(mapStateToProps, {})(AddModal);
