import React, { Component } from 'react';
import './../../Modal.css';
import { usernotes } from '../../../../apis/backend';
import { getContrastColor } from '../../../../apis/colors';
import { connect } from 'react-redux';
import {
  updateSelectedUser,
  updateSelectedNote
} from '../../../../actions/adminActions/usersActions';

class CreateNotesModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      note: '',
      flag: 0
      // size: 1,
    };
  }

  async notesUpdateRequest() {
    // flagStatus can be either 'cleared', 'notCleared', or 'none'
    let flagStatus = 'none';
    if (this.state.flag > 0) {
      const selectedFlag = this.props.noteFlags.find((obj) => obj.id === this.state.flag);
      if (selectedFlag.daysTillCleared > 0) {
        flagStatus = 'autoClears'; // Flag clears automatically
      } else if (selectedFlag.disableCheckin || selectedFlag.clearByAdmin) {
        flagStatus = 'notCleared'; // Flag must be cleared manually
      }
    }
    await usernotes
      .post(
        '/',
        {
          note: this.state.note,
          userId: this.props.selectedUser,
          flag: this.state.flag,
          flagStatus: flagStatus
        },
        { headers: { authorization: this.props.employeeToken } }
      )
      .catch((response) => console.log(response));
    this.setState({
      note: '',
      flag: 0
    });
  }

  getFlagOptions() {
    return (
      <>
        {this.props.noteFlags.map((flag) => (
          <option key={flag.id} value={flag.id}>
            {flag.name}
          </option>
        ))}
      </>
    );
  }

  render() {
    const user = this.props.users.find((obj) => obj.id === this.props.selectedUser);
    return (
      <div className="modal fade" id="createNotesModal">
        <div className="modal-dialog modal-dialog-centered lg">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">
                Note For {user?.firstName} {user?.lastName}
              </h5>
              <button
                type="button"
                data-target="#notesModal"
                className="close"
                data-toggle="modal"
                data-dismiss="modal"
                onClick={() => {
                  // this.props.updateSelectedUser(0);
                }}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="input-group input-group-sm mb-3">
                <textarea
                  className="form-control"
                  style={{ height: '120px' }}
                  aria-label="Sizing example input"
                  aria-describedby="inputGroup-sizing-sm"
                  value={this.state.note}
                  placeholder="Enter notes here..."
                  onChange={(e) => this.setState({ note: e.target.value })}
                />
              </div>
              <div className="input-group input-group-sm">
                <div className="input-group-prepend">
                  <span className="input-group-text" id="inputGroup-sizing-sm">
                    Flag
                  </span>
                </div>
                <select
                  className="form-control"
                  // size={this.state.size}
                  // onFocus={() => this.setState({ size: 3 } )}
                  // onBlur={() => this.setState({ size: 1 }) }
                  value={this.state.flag}
                  onChange={(e) => {
                    // e.target.blur();
                    this.setState({ flag: parseInt(e.target.value) });
                  }}
                >
                  <option value={0}>None</option>
                  {this.getFlagOptions()}
                </select>
              </div>
            </div>

            <div className="modal-footer">
              <button
                type="button"
                data-target="#notesModal"
                className="btn btn-secondary"
                data-toggle="modal"
                data-dismiss="modal"
                onClick={() => {
                  // this.props.updateSelectedUser(0);
                }}
              >
                Cancel
              </button>
              <button
                type="button"
                data-target="#notesModal"
                className="btn btn-dark"
                data-toggle="modal"
                data-dismiss="modal"
                onClick={() => {
                  this.notesUpdateRequest();
                  // this.props.updateSelectedUser(0);
                }}
                disabled={!(this.state.note !== '')}
              >
                Create Note
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  users: state.admin.admin.users,
  userNotes: state.admin.admin.userNotes,
  noteFlags: state.admin.admin.noteFlags,
  selectedUser: state.admin.users.selectedUser,
  selectedNote: state.admin.users.selectedNote,
  employeeToken: state.auth.employeeToken
});

export default connect(mapStateToProps, {
  updateSelectedUser,
  updateSelectedNote
})(CreateNotesModal);
