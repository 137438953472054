import React, { Component } from 'react';
import '../../Modal.css';
import { newsLog } from '../../../../apis/backend';
import { connect } from 'react-redux';

class AddModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      time: '',
      title: '',
      message: '',
      visible: ''
    };
  }

  async newsRequest() {
    try {
      // const dateOfBirth = new Date(this.state.dateOfBirth);

      const date = new Date();
      const currentTime = date.getTime();
      await newsLog
        .post(
          '/',
          {
            // "field in the db that you are adding: data to be added"
            // look in the backend/api/models to see which fields are required
            time: currentTime,
            title: this.state.title,
            message: this.state.message,
            visible: this.state.visible
          },
          { headers: { authorization: this.props.employeeToken } }
        )
        .catch((response) => {
          console.log(response);
          alert('Sorry, an error occurred. Please check your entries.');
        });
    } catch (err) {
      console.log('Request Failed.');
    }
  }

  render() {
    return (
      <div id="addModal" className="modal fade" style={{ overflow: 'scroll' }}>
        <div className="modal-dialog modal-dialog-centered lg">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Add news</h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="input-group input-group-sm mb-3">
                <div className="input-group-prepend">
                  <span className="input-group-text" id="inputGroup-sizing-sm">
                    Title
                  </span>
                </div>
                <input
                  type="text"
                  className="form-control"
                  aria-label="Sizing example input"
                  aria-describedby="inputGroup-sizing-sm"
                  value={this.state.title}
                  placeholder="Title"
                  onChange={(e) => this.setState({ title: e.target.value })}
                />
              </div>
              <div className="input-group input-group-sm mb-3">
                <div className="input-group-prepend">
                  <span className="input-group-text" id="inputGroup-sizing-sm">
                    Message
                  </span>
                </div>
                <textarea
                  type="text"
                  className="form-control"
                  aria-label="Sizing example input"
                  aria-describedby="inputGroup-sizing-sm"
                  value={this.state.message}
                  placeholder="Message"
                  onChange={(e) => this.setState({ message: e.target.value })}
                />
              </div>
              <div className="input-group input-group-sm mb-3">
                <div className="input-group-prepend">
                  <span className="input-group-text" id="inputGroup-sizing-sm">
                    Visibility
                  </span>
                </div>
                <select
                  className="form-control"
                  aria-label="Sizing example input"
                  aria-describedby="inputGroup-sizing-sm"
                  value={this.state.visible}
                  onChange={(e) => this.setState({ visible: e.target.value })}
                >
                  <option value={''}>Please Select</option>
                  <option value={false}> Not Visible</option>
                  <option value={true}> Visible</option>
                </select>
              </div>
            </div>

            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-dismiss="modal">
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-dark"
                data-dismiss="modal"
                onClick={() => {
                  this.newsRequest();
                }}
                disabled={
                  !(
                    this.state.title !== '' &&
                    this.state.message !== '' &&
                    this.state.visible !== ''
                  )
                }
              >
                Create News
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  employeeToken: state.auth.employeeToken
});

export default connect(mapStateToProps, {})(AddModal);
