import React, { Component } from 'react';
import '../../Modal.css';
import '../events.css';
import { connect } from 'react-redux';
import { usersEvents } from '../../../../apis/backend.js';

class UserAttendanceModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: '',
      eventName: '',
      cashAttendance: 0,
      ticketAttendance: 0,
      allAccessAttendance: 0,
      eventAttendance: []
    };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.selectedEvent !== this.props.selectedEvent && this.props.selectedEvent !== 0) {
      const event = this.props.events.find((obj) => obj.id === this.props.selectedEvent);
      if (event) {
        this.setState({
          eventName: event.eventName,
          cashAttendance: event.cashAttendance,
          ticketAttendance: event.ticketAttendance,
          allAccessAttendance: event.allAccessAttendance
        });
      }
      this.getEventAttendanceById();
    }
    if (prevProps.selectedEvent !== this.props.selectedEvent && this.props.selectedEvent === 0) {
      this.setState({ eventAttendance: [] });
    }
  }

  formatDate(eventDate) {
    const date = new Date(eventDate);
    const options = {
      hour: 'numeric',
      minute: 'numeric',
      hour12: true
    };
    return `${date.toDateString()}, ${date.toLocaleTimeString(undefined, options)}`;
  }

  totalAttendence = (ticket, cash, aap) => {
    const total = ticket + cash + aap;
    return total;
  };

  filterUsersEvent = (item) => {
    if (item.event === this.props.selectedEvent) {
      return item;
    }
  };

  renderAttendance() {
    return (
      <>
        {this.state.eventAttendance.map((log) => {
          const selectedUser = this.props.users.find((obj) => obj.id === log.user);
          return (
            <tr key={log.id}>
              <td className="text-center">{selectedUser.firstName}</td>
              <td className="text-center">{selectedUser.lastName}</td>
              <td className="text-center">{this.formatDate(log.checkInTime)}</td>
            </tr>
          );
        })}
      </>
    );
  }

  async getEventAttendanceById() {
    await usersEvents
      .get(`/findbyevent/${this.props.selectedEvent}`, {
        headers: { authorization: this.props.employeeToken }
      })
      .then((res) => {
        this.setState({ eventAttendance: res.data });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  render() {
    return (
      <div
        className="modal fade"
        id="userAttendanceModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered lg">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Attendance
              </h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <h5 className="modal-title">General</h5>
              <table
                className="table table-striped table-bordered table-hover bg-white mr-3"
                id="tab_logic"
              >
                <thead className=" bg-dark thead-dark">
                  <tr>
                    <th>All Access</th>
                    <th>Ticket</th>
                    <th>Cash</th>
                    <th>Total</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th>{this.state.allAccessAttendance}</th>
                    <th>{this.state.ticketAttendance}</th>
                    <th>{this.state.cashAttendance}</th>
                    <th>
                      {this.totalAttendence(
                        this.state.ticketAttendance,
                        this.state.cashAttendance,
                        this.state.allAccessAttendance
                      )}
                    </th>
                  </tr>
                </tbody>
              </table>
              <h5 className="modal-title mt-2">All Access Attendance</h5>
              <div className="eventAttendance-modal-body">
                <table
                  className="table table-striped table-bordered table-hover bg-white mr-3"
                  id="tab_logic"
                >
                  <thead className=" bg-dark thead-dark">
                    <tr>
                      <th>First Name</th>
                      <th>Last Name</th>
                      <th>Check-In Time</th>
                    </tr>
                  </thead>
                  <tbody>{this.renderAttendance()}</tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  events: state.admin.admin.events,
  users: state.admin.admin.users,
  selectedEvent: state.admin.events.selectedEvent,
  employeeToken: state.auth.employeeToken
});

export default connect(mapStateToProps, {})(UserAttendanceModal);
