import React, { Component } from 'react';
import '../../Modal.css';
import { connect } from 'react-redux';
import {
  updateRegistrationReady,
  updateRaspberryPiSelected
} from '../../../../actions/adminActions/scannersActions';
import notificationAudio from '../../../../audio/notification.mp3';

class RegistrationModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      raspberryPis: []
    };
  }

  playSound(audio, volume) {
    const sound = new Audio(audio);
    sound.volume = volume;
    sound.play();
  }

  async componentDidMount() {
    try {
      await this.props.io.get('/rfid/subscribe', () => {
        // console.log('Listening for unregistered RFID...');
      });
      this.props.io.on('rfidregister', (event) => {
        // console.log(event);
        if (this.state.raspberryPis.find((obj) => obj.mac === event.mac) === undefined) {
          this.setState({
            raspberryPis: [...this.state.raspberryPis, event]
          });
          this.playSound(notificationAudio, 0.5);
        }
      });
    } catch (err) {
      console.log(err);
    }
  }

  componentWillUnmount() {
    this.props.io.off('rfidregister');
  }

  renderRfids() {
    //renders the scanners table
    return (
      <>
        {this.state.raspberryPis.map((pi) => (
          <tr key={pi.mac} id={pi.mac}>
            <td className="text-center">{pi.mac}</td>
            <td className="text-center">
              <div
                className="btn btn-sm btn-dark"
                data-target="#addModal"
                data-toggle="modal"
                data-dismiss="modal"
                onClick={() => {
                  this.props.updateRaspberryPiSelected(pi.mac);
                }}
              >
                Register
              </div>
            </td>
          </tr>
        ))}
      </>
    );
  }

  render() {
    return (
      <div id="registerModal" className="modal fade" style={{ overflow: 'scroll' }}>
        <div className="modal-dialog modal-dialog-centered lg">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Add Scanner</h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => this.props.updateRegistrationReady(false)}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body" style={{ textAlign: 'center' }}>
              <h5>Listening for scanners...</h5>
              <div className="table-wrapper-scroll-y my-custom-scrollbar">
                <table
                  className="table table-striped table-bordered table-hover bg-white mr-3"
                  id="tab_logic"
                >
                  <thead className=" bg-dark thead-dark">
                    <tr>
                      <th>Pi MAC Address</th>
                      <th>Register</th>
                    </tr>
                  </thead>
                  <tbody>{this.renderRfids()}</tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  io: state.application.io
});

export default connect(mapStateToProps, {
  updateRegistrationReady,
  updateRaspberryPiSelected
})(RegistrationModal);
