import React from 'react';
import './add-user.css';
import { connect } from 'react-redux';
import Page from '../../utils/page/page.jsx';
import { users } from '../../../apis/backend.js';

class AddUser extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      firstName: '',
      lastName: '',
      birthday: '',
      zip: ''
    };
  }

  handleInputChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };

  isFormValid = () => {
    const { firstName, lastName, birthday, zip } = this.state;
    return firstName.trim() !== '' && lastName.trim() !== '' && birthday !== '' && zip !== '';
  };

  createUser = async () => {
    await users
      .post(
        '/',
        {
          firstName: this.state.firstName,
          lastName: this.state.lastName,
          dateOfBirth: this.state.birthday,
          zipcode: this.state.zip
        },
        { headers: { authorization: this.props.employeeToken } }
      )
      .catch((response) => console.log(response));
    this.props.history.push('/activities/purchasecheckin');
  };

  renderButtons() {
    return (
      <div className="addUserButtonsContain">
        <div className="addUserButtonContain" style={{ justifyContent: 'flex-start' }}>
          <button
            className="addUserButton"
            onClick={() => this.props.history.push('/activities/purchasecheckin')}
          >
            Back
          </button>
        </div>
        <div className="addUserButtonContain" />
        <div className="addUserButtonContain" style={{ justifyContent: 'flex-end' }}>
          <button
            className="addUserButton"
            onClick={() => this.createUser()}
            disabled={!this.isFormValid()}
          >
            Add User
          </button>
        </div>
      </div>
    );
  }

  render() {
    return (
      <Page>
        <div
          className="addUserPage"
          style={{
            height: 'calc(' + 90 + '% - ' + 36 + 'px)',
            backgroundColor: 'white',
            overflow: 'hidden'
          }}
        >
          <div
            className="addUserInputLabel"
            style={{
              textAlign: 'center',
              borderBottom: 'solid 3px gray',
              marginBottom: '15px',
              paddingBottom: '10px'
            }}
          >
            User Information
          </div>
          <div className="addUserRow">
            <div className="addUserColumn">
              <div className="addUserInputLabel">First Name</div>
              <input
                className="addUserInput"
                type="text"
                name="firstName"
                placeholder="First Name"
                value={this.state.firstName}
                onChange={this.handleInputChange}
              />
              <div className="addUserInputLabel">Birthday</div>
              <input
                className="addUserInput"
                type="date"
                name="birthday"
                placeholder="Birthday"
                value={this.state.birthday}
                onChange={this.handleInputChange}
              />
            </div>
            <div className="addUserColumn">
              <div className="addUserInputLabel">Last Name</div>
              <input
                className="addUserInput"
                type="text"
                name="lastName"
                placeholder="Last Name"
                value={this.state.lastName}
                onChange={this.handleInputChange}
              />
              <div className="addUserInputLabel">Zip</div>
              <input
                className="addUserInput"
                type="number"
                name="zip"
                placeholder="Zip"
                value={this.state.zip}
                onChange={this.handleInputChange}
                pattern="[0-9]{5}"
                title="Please enter a valid 5-digit zip code"
              />
            </div>
          </div>
        </div>
        {this.renderButtons()}
      </Page>
    );
  }
}

const mapStateToProps = (state) => ({
  name: state.activities.checkin.name,
  users: state.activities.activities.users,
  selected: state.activities.checkin.selected,
  employeeToken: state.auth.employeeToken
});

export default connect(mapStateToProps)(AddUser);
