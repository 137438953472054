import React, { Component } from 'react';
import '../../Modal.css';
import { employeeAuth, systemEmployee } from '../../../../apis/backend';
import { connect } from 'react-redux';
import { updateSelectedEmployee } from '../../../../actions/adminActions/employeesActions';

class PermissionsModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      permissions: []
    };
  }

  async componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      prevProps.selectedEmployee !== this.props.selectedEmployee &&
      this.props.selectedEmployee !== 0
    ) {
      await employeeAuth
        .get(`/permissions/${this.props.selectedEmployee}`, {
          params: { sysId: this.props.sysId, empId: this.props.empId },
          headers: { authorization: this.props.employeeToken }
        })
        .then((res) => this.setState({ permissions: res.data }))
        .catch((err) => console.log(err));
    }
  }

  async removePermission(id) {
    await systemEmployee
      .delete(`/${id}`, {
        headers: { authorization: this.props.employeeToken }
      })
      .then((res) => {
        if (res.data.length > 0) {
          const { permissions } = this.state;
          permissions.find((obj) => obj.sysId === res.data[0].systemUser).id = undefined;
          this.setState({ permissions });
        }
      })
      .catch((err) => console.log(err));
  }

  async addPermission(systemUser, employeeUser) {
    await systemEmployee
      .post(
        '/',
        { systemUser, employeeUser },
        { headers: { authorization: this.props.employeeToken } }
      )
      .then((res) => {
        const { permissions } = this.state;
        permissions.find((obj) => obj.sysId === res.data.systemUser).id = res.data.id;
        this.setState({ permissions });
      })
      .catch((err) => console.log(err));
  }

  render() {
    return (
      <div id="permissionsModal" className="modal fade" style={{ overflow: 'scroll' }}>
        <div className="modal-dialog modal-dialog-centered lg">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Edit Employee</h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => this.props.updateSelectedEmployee(0)}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              {this.state.permissions.map((permission) => (
                <div
                  key={this.state.permissions.indexOf(permission)}
                  className="input-group input-group-sm mb-3"
                >
                  <div className="input-group-prepend">
                    <span className="input-group-text" id="inputGroup-sizing-sm">
                      {permission.name}
                    </span>
                  </div>
                  <select
                    className="form-control"
                    value={permission.id ? 1 : 0}
                    onChange={async (e) => {
                      if (parseInt(e.target.value) === 1) {
                        this.addPermission(permission.sysId, this.props.selectedEmployee);
                      } else {
                        this.removePermission(permission.id);
                      }
                    }}
                  >
                    <option value={0}>No</option>
                    <option value={1}>Yes</option>
                  </select>
                </div>
              ))}
            </div>

            <div className="modal-footer">
              <span>Changes here saved automatically.</span>
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
                onClick={() => this.props.updateSelectedEmployee(0)}
              >
                Dismiss
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  employees: state.admin.admin.employees,
  sysEmpAccesses: state.admin.admin.sysEmpAccesses,
  selectedEmployee: state.admin.employees.selectedEmployee,
  employeeToken: state.auth.employeeToken,
  sysId: state.auth.sysId,
  empId: state.auth.empId
});

export default connect(mapStateToProps, { updateSelectedEmployee })(PermissionsModal);
